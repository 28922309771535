// This utility has been developed for the only purpose to patch data
// It has been tested only with simple data, dictionaries and arrays.

/**
 * Helper function to manage array cases of immutablePatch behavior
 * @param {*} src
 * @param {*} patch
 * @returns
 */
function ipArray(src, patch) {
  let copy = src.slice();
  patch.forEach((value, i) => {
    copy[i] = immutablePatch(copy[i], value);
  });
  return copy;
}

/**
 * Helper function to manage dict cases of immutablePatch behavior
 * @param {*} src
 * @param {*} patch
 * @returns
 */
function ipObject(src, patch) {
  let copy = { ...src };
  Object.entries(patch).forEach(([key, value]) => {
    if (key in copy) {
      copy[key] = immutablePatch(copy[key], value);
    } else {
      copy[key] = value;
    }
  });
  return copy;
}

/**
 * This functions creates a mix of shallow and deep copy of src with the changes specified in patch.
 * As its purpose is to handle modifications in the data obtainable from data folder,
 * this method doesn't take into acount classes/functions/etc., it's limited to the expected use of data files at `src/data`.
 *
 * It only manages special cases for arrays and dictionaries, so it will:
 *  - Clone src dict where it changes and/or add entries as they exist in the patch dict.
 *  - Clone src array where it changes and/or add values as they exist in the patch array.
 *  - Avoid to override src version at cloning when the new value is `undefined` or `null`.
 *  - Set the rest of data from patch to cloned version as in a shallow copy if exist.
 *
 * @param {*} src
 * @param {*} patch
 * @returns patchedSrcCopy
 */
function immutablePatch(src, patch) {
  if ([undefined, null].includes(patch)) return src;
  if (Array.isArray(src)) return ipArray(src, patch);
  if (typeof src === "object") return ipObject(src, patch);
  return patch;
}

export default immutablePatch;
