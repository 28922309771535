import React from "react";
import PropTypes from "prop-types";
import Page from "../components/Page";
import staticData from "../data/index";
import processData from "../data/processData";
import immutablePatch from "../lib/immutablePatch.js";

const PatchedTemplate = ({ pageContext: { name, patch } }) => {
  const patchedStaticData = immutablePatch(staticData[name], patch);
  const data = processData(patchedStaticData, {}, name);
  return <Page {...{ name, data }} />;
};

PatchedTemplate.propTypes = {
  pageContext: PropTypes.shape({
    name: PropTypes.string,
    patch: PropTypes.shape({}),
  }),
};

export default PatchedTemplate;
